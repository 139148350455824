<template>
  <div class="component-wrapper">
    <h1 class="text-center">
      <span>Hi, </span>
      <i>{{ pascalCase(user.name) }}</i>
    </h1>

    <b-card title="Nawala Status">
      <b-list-group>
        <b-list-group-item variant="danger">Blocked</b-list-group-item>
        <b-list-group-item>
          <div class="d-flex justify-content-between">
            <span>BTV168</span>
            <small class="text-danger">30 mins ago</small>
          </div>
        </b-list-group-item>
        <b-list-group-item>
          <div class="d-flex justify-content-between">
            <span>BTV168</span>
            <small class="text-danger">30 mins ago</small>
          </div>
        </b-list-group-item>
        <b-list-group-item>
          <div class="d-flex justify-content-between">
            <span>BTV168</span>
            <small class="text-danger">30 mins ago</small>
          </div>
        </b-list-group-item>
      </b-list-group>
      <b-list-group class="mt-1">
        <b-list-group-item variant="success">Safe</b-list-group-item>
        <b-list-group-item>
          <div class="d-flex justify-content-between">
            <span>BTV168</span>
            <small class="text-success">30 mins ago</small>
          </div>
        </b-list-group-item>
        <b-list-group-item>
          <div class="d-flex justify-content-between">
            <span>BTV168</span>
            <small class="text-success">30 mins ago</small>
          </div>
        </b-list-group-item>
        <b-list-group-item>
          <div class="d-flex justify-content-between">
            <span>BTV168</span>
            <small class="text-success">30 mins ago</small>
          </div>
        </b-list-group-item>
      </b-list-group>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: `MainDashboard`,
  computed: {
    ...mapState("oidc", ["user"]),
  },
};
</script>
